import React from 'react'

export default function ErrorPages() {
    return (
        <div className='container'><h2>
            ErrorPages
        </h2>
        </div>
    )
}
