export const DOMAIN_CYBER_USER = `http://casestudy.cyberlearn.vn/api/Users/`

export const TOKEN = 'access_token'
export const USER_LOGIN = 'user_login'
export const USER_SIGN_UP = 'user_sign_up'



export const SIGN_UP = ' SIGN_UP'
export const SIGN_IN = 'SIGN_IN'



export const SUCCESS = 200